import React from 'react';
import constate from 'constate';
import LoadScript from 'react-load-script';
import { GoogleLogin } from 'react-google-login';
import useLocalStorage from './useLocalStorage';

const useToken = () => {
  const [token, setToken, deleteToken] = useLocalStorage('gtoken');

  if (process.env.REACT_APP_USE_AUTH_TOKEN) {
    return {
      token: process.env.REACT_APP_USE_AUTH_TOKEN,
      setToken: () => {},
      deleteToken: () => {},
      deleteIfCurrentToken: () => {},
    };
  }

  const deleteIfCurrentToken = (oldToken) => {
    if (oldToken === token) {
      deleteToken();

      // eslint-disable-next-line
      window?.google?.accounts?.id?.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        auto_select: true,
        callback: ({ credential }) => setToken(credential),
      });

      // eslint-disable-next-line
      window?.google?.accounts?.id?.prompt();
    }
  };
  return {
    token, setToken, deleteToken, deleteIfCurrentToken,
  };
};
const [AuthProvider, useAuthContext] = constate(useToken);

export { AuthProvider, useAuthContext };

const jwtAge = (jwt) => {
  try {
    const { iat } = JSON.parse(atob(jwt.split('.')[1]));
    const ageInMs = new Date(iat * 1000);
    const diffMs = (ageInMs - new Date());
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    return diffMins;
  } catch (e) {
    return 0;
  }
};

export const GoogleSingleTap = () => {
  const { token, setToken } = useAuthContext();
  return (
    <LoadScript
      url="https://accounts.google.com/gsi/client"
      onLoad={() => {
        if (!token || jwtAge(token) > 30) {
          // eslint-disable-next-line
          window?.google?.accounts?.id?.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            auto_select: true,
            callback: ({ credential }) => setToken(credential),
          });

          // eslint-disable-next-line
          window?.google?.accounts?.id?.prompt();
        }
      }}
    />
  );
};

export const GoogleButton = () => {
  const { setToken, deleteIfCurrentToken } = useAuthContext();

  let token = null;
  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      buttonText="Login"
      onSuccess={(res) => {
        const { tokenObj: { id_token: idToken } } = res;
        token = idToken;
        setToken(token);
      }}
      onFailure={(r) => {
        console.warn('403 found, should delete token (google failure)', r);
        deleteIfCurrentToken(token);
      }}
      cookiePolicy="single_host_origin"
      // autoLoad={true}
      hostedDomain="qloo.com"
    />
  );
};
