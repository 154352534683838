import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useLocation, Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Drawer from './Drawer';
import { ReactComponent as Logo } from '../Logo.svg';
import Logosm from '../LogosmWhite.svg';
import HeaderSearchField from './HeaderSearchField';
import {
  TABS, USABLE_PAGES, SEARCH_ENABLED, MULTIPLE_PAGES,
} from '../../lib/pages';

const HideOnScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStylesTabs = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    width: '100%',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  navContainer: {
    width: '234px',
    overflow: 'hidden',
    alignSelf: 'flex-start',
  },
  navItem: {
    alignSelf: 'flex-start',
  },
  navIcon: {
    verticalAlign: 'middle',
  },
  navText: {
    verticalAlign: 'middle',
    paddingLeft: '20px',
  },
}));

const NavItem = ({ Icon, text }) => {
  const classes = useStylesTabs();

  return (
    <div className={classes.navItem}>
      <Icon className={classes.navIcon} />
      <span className={classes.navText}>{text}</span>
    </div>
  );
};

export const TAB_LIST = Object.entries(TABS)
  .filter(([k]) => !USABLE_PAGES || USABLE_PAGES.indexOf(k) > -1)
  .map(([, v]) => v);

const NavigationTabs = () => {
  const classes = useStylesTabs();
  // const [tab, setTab] = useState('entities');
  const location = useLocation();
  // /base/second --> /base
  const baseRoute = location.pathname?.split('/')?.splice(0, 2)?.join('/');
  const matchWithAlias = TAB_LIST
    .find((tab) => [].concat(tab.to, tab.alias).indexOf(baseRoute) > -1)?.to;

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={matchWithAlias}
        // onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {
          TAB_LIST.map((tab, ii) => (
            <Tab
              component={Link}
              // eslint-disable-next-line react/no-array-index-key
              key={ii}
              to={tab.to}
              value={tab.to}
              className={classes.navContainer}
              label={<NavItem Icon={tab.icon} text={tab.label} />}
              {...a11yProps(ii)}
            />
          ))
        }
      </Tabs>
    </div>
  );
};

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#1F2C3B',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  menuIcon: {
    [theme.breakpoints.down('sm')]: {
      background: 'transparent no-repeat center center',
      backgroundImage: `url(${Logosm})`,
      fill: 'transparent',
    },
  },
  logoContainer: {
    minWidth: '80px',
  },
  logoContainerAdaptable: {
    marginLeft: '1%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo: {
    opacity: 0.9,
    height: '41px',
    fill: 'white',
    verticalAlign: 'middle',
  },
  inputRoot: {
    color: 'inherit',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
    // [theme.breakpoints.up('sm')]: {
    //   // width: theme.spacing(9) + 1,
    // },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },

}));

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <HideOnScroll>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            {
              !!MULTIPLE_PAGES
              && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon className={classes.menuIcon} />
                </IconButton>
              )
            }

            <Link to={MULTIPLE_PAGES ? '/' : USABLE_PAGES?.[0] || '/'}>
              <Typography
                variant="h6"
                noWrap
                className={clsx(
                  classes.logoContainer,
                  { [classes.logoContainerAdaptable]: MULTIPLE_PAGES },
                )}
              >
                <Logo className={classes.logo} />
              </Typography>
            </Link>

            {
              !!SEARCH_ENABLED && <HeaderSearchField />
            }

            <div className={classes.grow} />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {
        !!MULTIPLE_PAGES
        && (
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>
            <Divider />

            <NavigationTabs />

          </Drawer>
        )
      }
    </>
  );
};

export default Header;
