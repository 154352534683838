import StorageIcon from '@material-ui/icons/Storage';
// import GroupIcon from '@material-ui/icons/Group';
import BuildIcon from '@material-ui/icons/Build';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LabelIcon from '@material-ui/icons/Label';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { ReactComponent as LogIcon } from '../components/icons/log.svg';

export const TABS = {
  requests: {
    label: 'Requests',
    to: '/requests',
    alias: '/recs',
    icon: BuildIcon,
  },

  // search: {
  //   label: 'Entities',
  //   to: '/search',
  //   alias: '/entity',
  //   icon: SearchIcon,
  // },

  tastedive: {
    label: 'Tastedive',
    to: '/db/admin',
    alias: '/db',
    icon: StorageIcon,
  },

  clients: {
    label: 'Logs',
    to: '/clients',
    alias: '/clients',
    icon: LogIcon,
    // icon: GroupIcon,
  },

  goals: {
    label: 'Goals',
    to: '/goals',
    icon: AssignmentTurnedInIcon,
  },

  audienceLabel: {
    label: 'Audiences',
    to: '/audience_label',
    icon: LabelIcon,
  },

  // shareables: {
  //   label: 'Shareables',
  //   to: '/shareables',
  //   icon: MenuBookIcon,
  // },

};

export const USABLE_PAGES = process.env.REACT_APP_PERMISSION_PAGES?.split(',') || Object.keys(TABS);
export const SEARCH_ENABLED = !process.env.REACT_APP_LAYOUT_SIMPLE;
export const ENTITY_VIEW_ENABLED = !process.env.REACT_APP_LAYOUT_SIMPLE;
export const MULTIPLE_PAGES = USABLE_PAGES.length > 1;
