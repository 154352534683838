import React from 'react';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  progressBar: {
    height: '3px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: 'rgba(201,206,234,0.8)',
  },
}));

const ProgressBar = ({ className }) => {
  const classes = useStyles();
  return <LinearProgress className={clsx(classes.progressBar, className)} color="secondary" />;
};

export default ProgressBar;
