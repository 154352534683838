import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { fade, makeStyles } from '@material-ui/core/styles';
import { useQueryParam, StringParam } from 'use-query-params';
import useDebounce from '../../lib/useDebounce';
import usePrevious from '../../lib/usePrevious';
import SearchField from '../SearchField';

export const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    maxWidth: '800px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      // width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.4,
  },
  inputInput: {
    color: 'white',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      // width: '20ch',
    },
  },
}));

const HeaderSearchField = () => {
  const classes = useStyles();
  const [query, setQuery] = useState();
  const history = useHistory();
  const deQuery = useDebounce(query, 250);
  const previousQuery = usePrevious(deQuery);

  useEffect(() => {
    const pushOrReplace = previousQuery ? 'replace' : 'push';
    if (deQuery) {
      history[pushOrReplace]({
        pathname: '/requests',
        search: !!query && (`?${new URLSearchParams({
          requests: `https://api.qloo.com/search?query=${query}`,
        }).toString()}`),
      });
    }
  }, [deQuery]);

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <SearchField
        // onClick={() => history.location.pathname !== '/requests' && history.push({
        //   pathname: '/requests',
        //   search: !!query && (`?${new URLSearchParams({
        //     requests: `https://api.qloo.com/search?query=${query}`,
        //   }).toString()}`),
        // })}
        className={classes.inputInput}
        // defaultValue={query}
        onBlur={() => setQuery('')}
        value={query}
        onUpdateSearch={setQuery}
      />
    </div>
  );
};

export default HeaderSearchField;
