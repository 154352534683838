import React, { lazy, Suspense, useEffect } from 'react';
import {
  HashRouter as Router, Route as BrowserRoute, Switch, Redirect,
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import Header, { TAB_LIST } from './components/Header';
import {
  AuthProvider, useAuthContext, GoogleSingleTap, GoogleButton,
} from './lib/AuthContext';
import ProgressBar from './components/ProgressBar';
import AccessDenied from './components/AccessDenied';
import '@qloo/design/src/colors.scss';

const TITLE = process.env.REACT_APP_SITE_TITLE || 'Qloo Insights';
const Route = ({ title, ...props }) => (
  <>
    <Helmet
      titleTemplate={`%s - ${TITLE}`}
      defaultTitle={TITLE}
    >
      <title>{title}</title>
    </Helmet>
    <BrowserRoute {...props} />
  </>
);
// const Home = lazy(() => import('./pages/Search'));
const Entity = lazy(() => import('./pages/Entity'));
// const Comparo = lazy(() => import('./pages/Comparo'));
const History = lazy(() => import('./pages/History'));
const RequestGrid = lazy(() => import('./pages/RequestGrid'));
const Logs = lazy(() => import('./pages/Logs'));
const HistoryQuery = lazy(() => import('./pages/HistoryQueryUrl'));
const TastediveAdmin = lazy(() => import('./TastediveAdmin'));
const Config = lazy(() => import('./pages/Config'));
const Clients = lazy(() => import('./pages/Clients'));
const ClientLogs = lazy(() => import('./pages/ClientLog'));
const AudienceLabel = lazy(() => import('./pages/AudienceLabel'));
const Goals = lazy(() => import('./pages/Tests'));
const Shareables = lazy(() => import('./pages/Shareables'));
// const Test = lazy(() => import('./pages/Test'));

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridGap: '0px',
    gridTemplateColumns: '120px auto',
    gridTemplateAreas: `"logo header"
                        "content content"`,
    backgroundColor: '#fff',

    [theme.breakpoints.down('m')]: {
      gridTemplateAreas: `"logo header"
                          "content content"`,
    },

    [theme.breakpoints.down('xs')]: {
      gridGap: '10px',
      gridTemplateColumns: 'auto',
      gridTemplateAreas: `
        "logo"
        "header"
        "content"
      `,
    },
  },
  content: {
    gridArea: 'content',
    backgroundColor: '#fff',
    margin: '40px 0px 64px 50px',
    [theme.breakpoints.down('xs')]: {
      margin: '40px 1%',
    },
    position: 'relative',
    // overflowX: 'scroll',
    // '&::-webkit-scrollbar': {
    //   background: 'transparent',
    // },

    // paddingLeft: '5px',
    // paddingRight: '5px',
    // [theme.breakpoints.up('sm')]: {
    //   paddingLeft: '20px',
    //   paddingRight: '20px',
    // },
  },
}));

const useConvertUrlsToHashUrls = () => {
  useEffect(() => {
    const oldStyle = window.location.pathname + window.location.search;
    if (oldStyle && oldStyle !== '/') {
      console.log('Replacing old style /path with hash #/path');
      const newurl = `/#${window.location.pathname}${window.location.search}`;
      window.history.replaceState({ path: newurl }, '', newurl);
    }
  }, []);
};

const WARNSTYLE = {
  opacity: '0.9',
  color: 'rgba(0,0,0,0.6)',
  margin: '35px 30px 0 30px',
  border: '1px solid rgba(125,125,0,0.5)',
  padding: '10px',
  background: 'rgba(255,255,0,0.5)',
};
const RouterPage = () => {
  const { token } = useAuthContext();
  const classes = useStyles();
  useConvertUrlsToHashUrls();

  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          <div className={classes.wrapper}>
            <Header />
            <div className={classes.content}>
              <div style={WARNSTYLE}>
                qloo.gl is moving <a href="https://insights.qloo.com">here</a>
              </div>
              {
                !token
                  ? <AccessDenied Button={GoogleButton} />
                  : (
                    <Suspense fallback={<ProgressBar />}>
                      <Switch>
                        <Redirect exact from="/" to={TAB_LIST[0].to} />
                        <Route path="/entity/:id/:slug?" component={Entity} title="Entity" />
                        <Redirect from="/entity" to="/search" />
                        {/* <Route path="/test" component={Test} title="Test" /> */}
                        {/* <Route path="/search" component={Home} title="Home" /> */}
                        <Route path="/history/query" component={HistoryQuery} title="HistoryQuery" />
                        <Route path="/history" component={History} title="History" />
                        <Route path="/requests" component={RequestGrid} title="Requests" />
                        <Redirect from="/recs" to="/requests" />
                        <Route path="/config" component={Config} title="Config" />
                        <Route path="/clients/:clientSlug" component={ClientLogs} title="Client Logs" />
                        <Route path="/clients" component={Clients} title="Clients" />
                        <Route path="/db/admin" component={TastediveAdmin} title="Database Admin" />
                        <Route path="/logs" component={Logs} title="Logs" />
                        <Route path="/audience_label" component={AudienceLabel} title="AudienceLabel" />
                        <Route path="/goals" component={Goals} title="Goals" />
                        <Route path="/shareables" component={Shareables} title="Shareables" />
                      </Switch>
                    </Suspense>
                  )
              }
            </div>
          </div>
        </SnackbarProvider>
      </QueryParamProvider>
    </Router>
  );
};

const RouterPageWithAuth = () => (
  <AuthProvider>
    <RouterPage />
    <GoogleSingleTap />
  </AuthProvider>
);

export default RouterPageWithAuth;
