/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  textField: {
    width: '98%',
    background: 'transparent',
    height: '44px',
    lineHeight: '44px',
    outline: 'none',
    font: '16px arial,sans-serif',
    border: 0,
    margin: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

class SearchField extends React.Component {
  state = {
    // value: '',
    // suggestions: [],
  };

  // handleSuggestionsClearRequested = () => this.updateSuggestions([]);

  // updateSuggestions = (suggestions) => {
  //   this.setState({ suggestions })
  //   this.props.onUpdateSuggestions && this.props.onUpdateSuggestions(suggestions)
  // }

  handleChange = (event) => {
    const { value } = event.target;

    this.props.onUpdateSearch && this.props.onUpdateSearch(value);
  };

  render() {
    const {
      classes, defaultValue, onUpdateSearch, value = '', ...rest
    } = this.props;

    return (
      <Input
        type="text"
        disableUnderline
        onChange={this.handleChange}
        className={classes.textField}
        defaultValue={defaultValue}
        value={value}
        {...rest}
      />
    );
  }
}

SearchField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchField);
